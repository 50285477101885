export default {
  primary: {
    color: 'light',
    backgroundColor: 'primary',
    padding: '1rem 3rem',
    fontize: '1.25rem',
    letterSpacing: '1px',
    transition: 'all ease-in-out 0.4s',
    fontFamily: 'body',
    borderRadius: '0px',
    textTransform: 'uppercase',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'secondary'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
