export default {
  text: 'black',
  primary: 'rgba(79,115,138)',
  // secondary: '#eda900',
  secondary: '#99381f',
  tertiary: '#4f738a',
  background: '#fffbd7',
  background: 'rgba(237,169,0,1)',
  backgroundSecondary: '#fffbd7',
  light: '#FFF',
  dark: '#333333'
}
