export default {
  locationsPage: {
    '.logo': {
      height: 'auto',
      img: {
        maxHeight: ['85px', '', '100px']
      }
    },
    '.location': {
      width: ['100%', '', '50%'],
      '.CTAButton': {
        margin: '0.5rem',
        width: 'calc(50% - 1rem)',
        backgroundColor: 'secondary',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        span: {
          whiteSpace: 'break-spaces',
          fontFamily: 'body'
        },
        ':hover': {
          opacity: '0.8'
        }
      },

      '.content': {
        width: '100%'
      },
      '.locationTitle': {
        fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
        mb: ['', '', '', '1.5rem']
      }
    },
    '.locationTitle': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },

    '.location0': {},
    '.location1': {},

    '.CTAS': {
      maxWidth: '400px',
      justifyContent: 'center',
      margin: 'auto',
      a: {
        flexGrow: '1',
        width: 'calc(50% - 1rem)',
        margin: '0.5rem',
        ':hover': {
          opacity: '0.8'
        }
      }
    },

    '.enterSiteButton': {
      variant: 'buttons.secondary',
      backgroundColor: 'secondary',
      padding: '0.75rem 1rem',
      color: 'white',
      borderColor: 'secondary',
      width: '100%',
      fontFamily: 'body',
      margin: '0rem',
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover': {
        opacity: '0.8'
      }
    }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  // navigation:{} // ! being shadowed

  footer: {
    paddingBottom: ['5.5rem', '', '2.5rem'],
    borderTop: '4px solid',
    borderColor: 'secondary',
    color: 'white',
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1601960386/sites/little-barn/background.jpg')",
    backgroundColor: 'black',
    backgroundSize: 'cover',
    '.multiButtonContainer a': {
      borderStyle: 'double',
      borderWidth: '4px'
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
    display: ['', '', '', 'none'],
    a: {
      fontSize: '1.2rem'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  sideBySide1: {
    padding: '1rem',
    '.content': {
      order: ['2', '', '2']
    },
    '.title': {
      color: 'secondary',
      textTransform: 'uppercase',
      fontSize: ['2rem', '', '', '2.5rem']
    },
    '.text': {
      fontSize: ['1.25rem', '', '', '1.75rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.image': {
      borderLeft: 'solid #99381f 9px',
      maxHeight: ['60vh', '', '50vh']
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      '.image': {
        maxHeight: '50vh',
        borderRight: 'solid #99381f 9px'
      }
    },
    '.content': {
      order: ['2', '', 'unset']
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageAbout: {
    '.title': {
      color: 'secondary',
      fontSize: ['2rem', '', '', '2.5rem']
    },
    '.text': {
      fontSize: ['1.25rem', '', '', '1.75rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.image': {
      display: ['none', '', 'block'],
      boxShadow: 'none'
    },
    '.content': {
      flexGrow: '1'
    },
    '.lazyload-wrapper': {
      width: '380px',
      height: '380px',
      display: ['none', '', 'block']
    },
    maxWidth: '1200px',
    margin: '0 auto',
    padding: ['3rem 1rem 0rem', '3rem 1rem 0rem', '3rem 1rem 0rem', '3rem 1rem 0rem']
  },

  homepageOnlineOrder: {
    variant: 'customVariants.sideBySide1'
  },

  homepagePretzel: {
    variant: 'customVariants.sideBySide1'
  },

  homepageAtmosphere: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageOutdoorDining: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContentBoxesTitle: {
    padding: ['4rem 1rem 0rem'],
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    maxWidth: 'unset',
    h2: {
      color: 'secondary',
      textTransform: 'uppercase',
      fontSize: ['2rem', '', '', '2.5rem'],
      paddingBottom: '1rem',
      borderBottom: '5px solid',
      width: 'fit-content',
      margin: '0 auto'
    }
  },

  homepageContentBoxes: {
    '> div > a': {
      alignItems: 'center',
      border: 'none',
      width: ['100%', '50%', '33%'],
      img: {
        height: ['130px', '150px', '200px'],
        width: 'auto'
      }
    },

    'div > a:nth-of-type(1)': {
      '.image': {
        filter: 'brightness(0) invert(1)'
      }
    },

    '.title': {
      color: '#434343',
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  },

  homepageSectionNavLinks: {
    marginTop: '2rem',
    '.lazyload-wrapper': {
      width: ['calc(100% - 1rem)', 'calc(50% - 2rem)'],
      margin: '1rem'
    },
    '.box': {
      borderLeft: 'solid #99381f 9px'
    }
  },

  homepageFindUsSection: {
    background: 'transparent',
    '.title': {
      color: 'secondary',
      fontSize: ['2rem', '', '', '2.5rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageImageSlider: {
    padding: '0rem',
    '> div': {
      padding: '0.5rem 0px'
    },
    '.slick-slide img': {
      margin: '0rem 0.75rem'
    },
    '.slick-dots': {
      display: 'none !important'
    },

    '.mainTextContainer': {
      '.title': {
        color: 'secondary',
        fontSize: ['2rem', '', '', '2.5rem']
      }
    }
  },

  homepageInstagram: {
    padding: '0rem',
    flexDirection: ['column', 'column', 'column'],
    width: '100%',
    '.logoAndTextContainer': {
      flexDirection: 'row',
      width: '100%',
      padding: '1rem',
      '.title': {
        color: 'secondary',
        fontSize: ['2rem', '', '', '2.5rem'],
        margin: '0rem'
      }
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%', '100%']
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  // ! no about page as not enough text

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: 'primary',
    color: 'light',
    '.textField': {
      borderColor: 'light',
      color: 'light',
      '::placeholder': {
        color: 'light'
      },
      '::-webkit-input-placeholder': {
        color: 'light'
      }
    },
    '.inputField': {
      color: 'light',
      borderColor: 'light',
      '::placeholder': {
        color: 'light'
      }
    },
    h2: {
      textTransform: 'uppercase',
      color: 'light'
    },
    '.submitBtn': {
      backgroundColor: 'secondary'
    }
  }
}
