export default {
  sideBySideV1: {
    container: {
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      paddingY: ['1rem', '2rem', '5rem'],
      paddingX: '1rem',
      '.lazyload-wrapper': {
        width: ['100%', '', '50%']
      }
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      a: {
        display: 'flex',
        width: 'fit-content'
      }
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      color: 'tertiary',
      textTransform: 'uppercase',
      fontSize: '2rem',
      marginBottom: 3
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      backgroundColor: 'primary',
      color: 'white',
      '&:hover': {
        backgroundColor: 'secondary',
        opacity: '0.8'
      }
    },

    text: {
      fontSize: '1.2rem'
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
      boxShadow: '2px 2px 8px black'
    }
  }
}
