export default {
  sectionNavLinksV2: {
    container: {
      padding: ['0.5rem 0rem', '', '3rem 0rem', '0rem 0rem 3rem'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%'],
        margin: ['1rem', '', '0rem', ''],
        height: ['50vw', '50vw', '33vh', '50vh']
      }
    },
    boxes: {
      maxWidth: '1800px',
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      display: 'flex'
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      height: '200px',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      boxShadow: '2px 2px 18px #1b1b1b',
      margin: ['', '', '', '0px'],
      height: '100%',
      // '&:hover': {
      //   backgroundPosition: 'bottom left',
      // },
      a: {
        width: '100%',
        padding: '1rem 2rem',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0rem',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        '&:hover > *': {
          backgroundColor: 'primary'
        }
      }
    },
    linkName: {
      width: '100%',
      color: 'light',
      backgroundColor: 'secondary',
      padding: 4,
      fontSize: 4,
      maxWidth: '250px',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'center',
      margin: ['0rem 0rem 0.5rem', '', '', '1rem'],
      width: 'fit-content',
      padding: ['0.5rem', '', '1rem'],
      backgroundColor: 'rgba(237, 169, 0,  0.78)'
    }
  }
}
