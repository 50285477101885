export default {
  heroV5a: {
    hero: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexDirection: ['column', '', '', 'column'],
      // height: ['', '', '', '100vh'],
      minHeight: ['', '', '', '100vh'],
      '.slick-slider': {
        width: ['100%', '', '', '100%'],
        height: ['', '', '', '100vh']
      },

      '.slick-dots': {
        width: 'fit-content',
        // left: '50%',
        right: '1rem',
        bottom: '1rem'
        // transform: 'translateX(-50%)',
      },

      '.slick-dots li.slick-active button:before, .slick-dots li button:before': {
        color: 'white'
      },

      '.slick-prev, .slick-next': {
        left: 'unset',
        top: 'unset',
        right: '1rem',
        bottom: '2rem',
        width: '30px',
        zIndex: '10',
        color: 'white'
      },

      '.slick-prev': {
        right: '3rem'
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        color: 'inherit',
        opacity: '0.8'
      },
      '.slick-prev, .slick-next': {
        color: 'primary'
      },

      '.slick-slide': {
        position: 'relative',
        '> div': {
          '::after': {
            content: "''",
            position: 'absolute',
            top: '0rem',
            left: '0rem',
            width: '100%',
            height: '100vh',
            background: 'linear-gradient(356deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 43%);',
            zIndex: '10'
          }
        }
      }
    },

    content: {
      position: ['', '', '', 'absolute'],
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: ['1rem 1rem 2rem', '', '', '0 0rem 0rem 2rem'],
      width: ['', '', '', '45%'],
      maxWidth: ['', '', '', '500px'],
      top: ['', '', '', '100vh'],
      transform: ['', '', '', 'translateY(-100%)'],
      left: ['', '', '', '2rem'],
      right: ['', '', '', 'unset'],
      color: ['', '', '', 'white'],
      '.gatsby-link': {
        display: ['none', '', '', ''],
        alignSelf: 'flex-end'
      },

      '.text': {
        color: ['', '', '', 'light'],
        maxWidth: '767px',
        p: {
          color: ['', '', '', 'light']
        }
      }
    },

    image: {
      width: '100%',
      objectFit: 'cover',
      height: ['50vh', '', '', '100vh']
    },

    logoHero: {
      maxHeight: ['175px', '', '200px', '250px'],
      marginBottom: 3
    },

    title: {
      fontSize: ['1.2rem', '1.5rem', '2rem'],
      marginBottom: 3,
      color: 'light'
    },
    subtitle: {
      fontWeight: 'bold',
      marginBottom: 3,
      fontSize: ['2rem', '', '', '2rem', '2.2rem'],
      textTransform: 'uppercase',
      color: ['secondary', '', '', 'light']
    },

    ctaButton: {
      marginTop: 1,
      marginRight: '0.5rem',
      borderBottom: '2px solid',
      paddingBottom: '0.5rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      '&:hover': {
        color: 'secondary'
      }
    },

    shoutWidget: {
      container: {
        width: '100%',
        // minHeight: '30vh',

        alignItems: 'stretch',
        // backgroundColor: ['', '', 'rgba(121, 121, 121, 0.8)'],
        alignSelf: 'flex-start',
        maxWidth: ['767px'],
        margin: '2rem auto 4rem',
        display: 'flex'
        // position: ['', '', 'absolute'],
        // transform: ['', '', 'translateY(-100%)', 'unset'],
        // padding: ['', '', '0.5rem;'],
        // left: ['', '', '', '0.5rem'],
        // boxShadow: ['none', 'none', '-2px 4px 2px 1px #070302cc'],
        // top: ['', '', '46vh', 'unset'],
        // bottom: ['', '', '', '2.5rem'],
        // backgroundColor: ['', '', 'rgba(187, 79, 42, 0.8)'],
        // marginBottom: ['4rem', '', '0rem'],
      },
      content: {
        order: '2',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        padding: ['1rem', '', '', '3rem'],
        color: 'text',
        width: ['55%', '55%', '55%', '55%']
      },
      title: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '&::after': {
          content: '""',
          position: 'relative',
          left: '-30px',
          width: '75px',
          height: '2px',
          marginTop: '0.5rem',
          backgroundColor: 'white',
          opacity: '0.7'
        },
        display: 'flex',
        flexDirection: 'column'
      },
      text: {
        marginY: 1,
        // color: ['secondary', '', 'background'],
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: ['1.25rem', '', '1.6rem']
      },
      date: {
        fontSize: 2,
        color: '#e0e0e0',
        fontWeight: 'bold'
      },
      imageContainer: {
        order: '1',
        position: 'relative',
        width: ['55%', '55%', '55%', '55%'],
        top: ['', '', '0px'],
        maxHeight: ['', '', '40vh'],
        // maxHeight: ['250px', '', '150px'],
        img: {
          borderRadius: ['0 10px 10px 0', '', '20px'],
          width: '100%',
          height: ['100%', '', ''],
          objectFit: 'cover',
          border: 'solid #cd7e3a 3px',
          height: '120%'
        }
      },

      shoutCTA: {
        variant: 'buttons.primary',
        padding: '0.5rem',
        margin: '0rem 0.5rem 0.5rem 0rem',
        ':hover': {
          backgroundColor: 'black'
        }
      }
    }
  }
}
