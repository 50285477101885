export default {
  aboutSectionV1: {
    container: {
      position: 'relative',
      whiteSpace: 'break-spaces',
      color: 'text',
      paddingX: 7,
      paddingY: 10,
      backgroundColor: 'background',
      backgroundAttachment: ['', '', '', 'fixed'],
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: '8rem 1rem',
      textAlign: 'center'
    },
    section: {
      maxWidth: '767px',
      margin: '0 auto',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'center'
    },
    title: {
      marginBottom: 3,
      color: 'primary',
      textTransform: 'uppercase',
      fontSize: '2rem'
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal'
    },
    text: {
      zIndex: '100',
      fontSize: '1.5rem',
      lineHeight: '1.75',
      marginY: 3,
      whiteSpace: 'break-spaces'
    },
    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      color: 'white',
      '&:hover': {
        backgroundColor: 'primary'
      }
    }
  }
}
