export default {
  heading: 'Vibrocentric, sans-serif',
  body: 'SchmutzICG Cleaned, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Reenie Beanie, cursive',
  googleFonts: ['Reenie Beanie'],
  customFamilies: ['Vibrocentric', 'SchmutzICG Cleaned'],
  customUrls: [
    'https://www.gonation.biz/fonts/vibrocentric/vibrocentric.css',
    'https://www.gonation.biz/fonts/schmutz-icg-cleaned/schmutz-icg-cleaned.css'
  ]
}
